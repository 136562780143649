<template>
  <div class="dashboard-wrap">
    <div class="dashboard">
      <div class="top-bar flex justify-between">
        <div class="flex items-center">
          <span class="font-bold">预订回访</span>
          <div class="top-label">{{ $route.query.orderDate }}</div>
          <div class="top-label">
            {{ ["早餐","午餐","晚餐","夜宵","午餐二轮","晚餐二轮"][$route.query.orderType]}}
          </div>
        </div>
        <div class="item-options">
          <a-button @click="onSendSMS()" type="primary" class="feedback">短信回访</a-button>
          <a-button @click="$router.go(-1)" type="primary">返回工作台</a-button>
        </div>
      </div>
      <a-table
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="pagination"
        @change="handleTableChange">
        <template #action="record">
          <template v-if="[0, 3].includes(record.confirm)">
            <a-button
              class="action-button"
              type="primary"
              size="small"
              ghost
              @click="actionButton(true, record.id)">
              确认
            </a-button>
            <a-button
              class="action-button"
              size="small"
              @click="actionButton(false, record.id)">
              取消
            </a-button>
          </template>
          <template v-else-if="[1, 4].includes(record.confirm)">已确认</template>
          <template v-else>取消</template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { bookReviewConfirm, bookReviewList, bookReviewSendSMS } from '@/api/dashboard/bookReview'
import { cancelOrderRoom } from '@/api/dashboard'
import { readDicFn } from '@/utils/utils'

export default {
  name: 'BookReview',
  data() {
    return {
      columns: [
        {
          title: '预订人',
          dataIndex: 'name',
          customRender: (text, record) =>
            `${text}${
              record.sex === 1 ? '先生' : record.sex === 2 ? '女士' : ''
            }`,
          ellipsis: true,
        },
        {
          title: '手机号',
          dataIndex: 'phone',
        },
        {
          title: '包间名称',
          dataIndex: 'with_room.title',
        },
        {
          title: '到店时间',
          dataIndex: 'order_time',
        },
        {
          title: '回访状态',
          dataIndex: 'confirm',
          customRender: confirm => readDicFn(confirm, this.confirmList),
        },
        {
          title: '操作',
          width: '150px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      confirmList: [
        { label: '未确认', value: 0 },
        { label: 'PC手动确认', value: 1 },
        { label: 'PC手动取消', value: 2 },
        { label: '已发送回访短信', value: 3 },
        { label: '顾客回复短信确认', value: 4 },
        { label: '顾客回复短信取消', value: 5 },
      ],
      list: [],
      pagination: {
        current: 1,
        defaultPageSize: 15,
        showTotal: (total) => `共${total}条`,
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(page = 1) {
      this.$store.dispatch('loading/startContentLoading')
      bookReviewList({
        page,
        order_date: this.$route.query.orderDate,
        order_type: this.$route.query.orderType,
      }).then((res) => {
        if (res.code === 0) {
          const pagination = { ...this.pagination }
          pagination.total = res.data.total
          this.list = res.data.data
          this.pagination = pagination
        }
        this.$store.dispatch('loading/endContentLoading')
      })
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getList(pagination.current)
    },
    /**
     * @description 确认或者取消预订
     * @param confirm 确认或者取消
     * @param id 操作订单id
     * */
    actionButton(confirm, id) {
      this.$confirm({
        title: '提示',
        content: confirm ? '确认该顾客能到店吗？' : '确认取消该顾客的预订吗？',
        onOk: () => {
          this.$message.loading({ content: '请稍等', key: 'confirm' })
          if (confirm) {
            bookReviewConfirm({ id }).then((res) => {
              if (res.code === 0) {
                this.getList()
              }
            })
          } else {
            cancelOrderRoom({ id }).then((res) => {
              if (res.code === 0) {
                this.getList()
              }
            })
          }
          this.$message.success({
            content: confirm ? '确认成功！' : '取消成功！',
            key: 'confirm',
          })
        },
      })
    },
    /**
     * @description: 发送短信回访
     * @return {*}
     */    
    async onSendSMS() {
      this.$message.loading({
        content: '短信发送中',
        key: 'sendSMS'
      }).then()
      let { code } = await bookReviewSendSMS({
        order_date: this.$route.query.orderDate,
        order_type: this.$route.query.orderType,
      });
      if (code == '0') {
        await this.$message.success({
          content: '短信发送成功！',
          key: 'sendSMS'
        })
        this.getList()
      }
    }
  },
}
</script>

<style scoped lang="less">
.dashboard-wrap {
  background-color: #f0f2f5;
  padding: 24px;
  box-sizing: border-box;
}

.dashboard {
  width: 100%;
  min-height: calc(100vh - 114px);
  background-color: #fff;
  border-radius: 4px;
  padding: 25px 22px;
  .feedback {
    margin-right: 15px;
  }
}

.top-bar {
  margin-bottom: 30px;
}

.top-label {
  padding: 2px 10px;
  border: 1px solid #e63e30;
  border-radius: 11px 11px 11px 0;
  font-size: 13px;
  color: #e63e30;
  height: 22px;
  box-sizing: border-box;
  margin-left: 10px;
}

.action-button {
  height: 32px;
  box-sizing: border-box;
  padding: 0 5px;
  margin-right: 10px;
}
</style>
