/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-03-22 15:17:52
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2023-04-17 16:16:47
 * @Description: 
 */
import request from '@/utils/request'

// 获取回访列表
export function bookReviewList(data) {
  return request({
    url: '/work/room/return_visit',
    method: 'post',
    data,
  })
}

// 短信回访
export function bookReviewSendSMS(data) {
  return request({
    url: '/work/room/order/send_confirm_sms',
    method: 'post',
    data,
  })
}

// 预定确认操作
export function bookReviewConfirm(data) {
  return request({
    url: '/work/room/confirm_order',
    method: 'post',
    data,
  })
}
